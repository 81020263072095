<template>
  <div >
    <div class="register">
      <p class="regtitle"><span>{{titlename}}</span></p>
      <ul>
        <li class="clearfix">
          <Input v-model="mobile" class="zhuceinput"  @blur.native.capture="getNumber" size="large" placeholder="请输入11位手机号码" />
        </li>
        <li class="clearfix">
          <Input  size="large" class="zhuceinput" v-model="txnumber" placeholder="图片验证码" style="width: 270px;float: left" />
          <div @click="fourCode" class="yzm_img">
            <canvas class="mycanvas" width="120" height="48">抱歉，您的浏览器不支持canvas元素，换个浏览器试试？</canvas>
          </div>
        </li>
        <li class="clearfix">
          <Input v-model="verCode" size="large" class="zhuceinput" placeholder="短信验证码" style="width: 270px;float: left" />
          <div class="yzm_img" :class="!login.canGet ? 'btn-disabled' : ''"  @click="getCode">
            <Button type="primary" v-show="login.canGet" style="width: 118px;font-size: 16px;">{{tempLogin.text}}</Button>
            <Button type="primary" v-show="!login.canGet"  :disabled="!login.canGet" style="width: 118px;background: #E6EBF5;color: #787A80;font-size: 13px">{{login.waitTime+"s"}}后重发</Button>
          </div>
        </li>
        <li class="clearfix">
          <Button @click="registClick" type="primary" style="width: 100%;font-size: 16px">{{buttomname}}</Button>
        </li>
      </ul>
      <p class="tspop">{{pmname}}成功后会有短信通知密码，请注意查收登录</p>
      <div class="zhanghu" v-show="zhanghu"><router-link :to="'/login'">已有帐号，立即登录</router-link></div>
    </div>
  </div>
</template>

<script>
  import NavHeadlogin from '@/components/content/mainTabbar/NavHeadlogin'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'

  import { sendSMSApi, registerApi, findPasswordApi } from 'network/login'

  export default {
    name: 'Register',
    data() {
      return {
        value: "",//随机图形验证码
        code: "1234567890abcdefghijklmnopqrstuvwxyz",
        titlename:"账号注册",//名称
        buttomname:"立即注册",//按钮名称
        pmname:"注册",//按钮名称
        zhanghu:true,//文案显示
        mobile:"",//手机号
        txnumber:"",//图形验证码
        verCode:"",//短信验证码
        tempLogin: { // 定义一个临时对象
          canGet: true,
          timer: null,
          waitTime: 60,
          text: '发送验证码'
        }
      };
    },
    components: {
      NavHeadlogin,
      LogoBar
    },
    created() {
      var type=this.$route.params.type
      if(type==1){
        this.titlename="找回密码"
        this.buttomname="确认"
        this.zhanghu=false
        this.pmname="验证"
        document.title = '找回密码'
      }else{
        this.titlename="账号注册"
        this.buttomname="立即注册"
        this.zhanghu=true
        this.pmname="注册"
        document.title = '注册'
      }
      // const listener = () => {
      //   setTimeout(() => {
      //     iframe.removeEventListener('load', listener);
      //   setTimeout(() => {
      //     document.body.removeChild(iframe);
      // }, 0);
      // }, 0);
      // };
      // iframe.addEventListener('load', listener);
      // document.body.appendChild(iframe);
    },
    mounted() {
      this.fourCode();
    },
    computed: {
      login () { // 最终对象
        const _this = this
        if (!this.tempLogin.canGet) {
          if (this.tempLogin.waitTime === 0) {
            _this.tempLogin.text = '重新发送'
          }
          return this.timeCountdown(this.tempLogin)
        } else {
          return this.tempLogin
        }
      },
    },
    methods: {
      // 手机号验证
      getNumber() {
        if (this.mobile === '') {
          this.$Message.warning('请输入手机号');
          return false
        } else {
          if (!/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.mobile)) {
            this.$Message.warning('手机号格式不正确');
            return false
          }
        }
      },
      // 图形验证码
      randomColor() {
        //得到随机的颜色值
        var r = Math.floor(Math.random() * 256);
        var g = Math.floor(Math.random() * 256);
        var b = Math.floor(Math.random() * 256);
        return "rgb(" + r + "," + g + "," + b + ")";
      },
      fourCode() {
        this.value="";
        for (let a = 0; a < 4; a++) {
          this.value += this.code1();
        }
        this.doDraw();
      },
      code1() {
        let index = Math.floor(Math.random() * (this.code.length - 1) + 1);
        return this.code[index];
      },
      doDraw() {
        var x = 25;
        var y = 0;
        var deg = (Math.random() * 30 * Math.PI) / 180;
        //  获取canvas
        var canvas = document.getElementsByClassName("mycanvas");
        canvas[0].width=canvas[0].width;//清空画布
        canvas[0].height=canvas[0].height;
        var canvas_width = canvas[0].width;
        var canvas_height = canvas[0].height;
        var context = canvas[0].getContext("2d");
        context.font = "bold 35px 微软雅黑";
        context.fillText(this.value, 15, 30);
        context.translate(x, y); //canvas原点的偏移量
        context.rotate(deg); //旋转度数
        context.fillStyle = this.randomColor(); //设置颜色
        context.strokeStyle = this.randomColor(); //设置颜色
        context.rotate(-deg);
        context.translate(-x, -y);
        for (var i = 0; i <= 15; i++) {
          //验证码上显示线条
          context.strokeStyle = this.randomColor(); //轮廓风格
          context.beginPath();
          context.moveTo(
            Math.random() * canvas_width,
            Math.random() * canvas_height
          );
          context.lineTo(
            Math.random() * canvas_width,
            Math.random() * canvas_height
          );
          context.stroke();
        }
        for (var i = 0; i <= 30; i++) {
          //验证码上显示小点
          context.strokeStyle = this.randomColor();
          context.beginPath(); //新建一条路径
          var x = Math.random() * canvas_width;
          var y = Math.random() * canvas_height;
          context.moveTo(x, y); //吧画笔移动到指定位置
          context.lineTo(x + 1, y + 1); //想指定位置移动
          context.stroke();
        }
      },
      // 发送验证码
      getCode () {
        // 请求接口开始，成功之后调用
        // 倒计时开始
        if (this.mobile === '') {
          this.$Message.warning('请输入手机号');
        } else if (!/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.mobile)) {
            this.$Message.warning('手机号格式不正确');
        }else{
          if(this.value==this.txnumber){
            this.sendSMS() // 发送短信
            this.timeCountdown(this.login) // 参数为最终对象
          }else if(this.txnumber==""){
            this.$Message.warning('请输入图形验证码');
          }else{
            this.$Message.warning('图形验证码不正确');
          }
        }
      },
      timeCountdown (obj) {
        // obj包括timer、waitTime 、canGet
        const TIME_COUNT = 60 // 默认倒计时秒数
        if (!obj.timer) {
          obj.waitTime = TIME_COUNT
          obj.canGet = false
          obj.timer = setInterval(() => {
            if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
            obj.waitTime--
            } else {
              obj.canGet = true
              clearInterval(obj.timer) // 清空定时器
              obj.timer = null
            this.fourCode();
            }
          }, 1000)
        }
        return {
          timer: obj.timer,
          canGet: obj.canGet,
          waitTime: obj.waitTime
        }
      },
      // api相关
      // 发送短信
      sendSMS (){
        sendSMSApi({mobile: this.mobile.trim()}).then(res => {
          if(res.code === 0) {
            this.$Message.success('发送成功！')
          }else{
          this.$Message.warning(res.errInfo)
          }
        })
      },
      // 注册
      registClick(){
        let data = {
          mobile: this.mobile.trim(),
          password: this.verCode.trim()
        }
        if(this.$route.params.type == 1) {
          findPasswordApi(data).then(res => {
            if(res.code === 0) {
            localStorage.setItem('mobile',this.mobile.trim())
              this.$Message.success('验证成功，登录密码已通过短信的方式发送到注册手机号码！')
              this.$router.push({path: '/login'})
            }else{
            this.$Message.warning(res.errInfo)
          }
          })
        } else {
          registerApi(data).then(res => {
            if(res.code === 0) {
              this.$Message.success('注册成功，登录密码已通过短信的方式发送到注册手机号码！')
            localStorage.setItem('mobile',this.mobile.trim())
              this.$router.push({path: '/login'})
            }else{
            this.$Message.warning(res.errInfo)
          }
          })
        }
      }
    }
  };

//   export default {
//   name: 'Register',
//   data() {
//     return {
//       value: "",
//       code: "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
//     };
//   },
//   components: {
//     NavHead,
//     LogoBar
//   },
//   mounted() {
//     this.fourCode();
//   },
// }
</script>

<style scoped>
  .mycanvas {
    border: 1px solid rgb(199, 198, 198);
    border-radius: 5px;
    height:48px;
  }
  .register{
    width: 410px;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 60px;
  }
  .register .regtitle{
    font-size: 16px;
    line-height: 60px;
    border-bottom: 1px solid #E6EBF5;
  }
  .register .regtitle span{
    position: relative;
    top: 1px;
    display: inline-block;
    border-bottom: 2px solid #25449A;
  }
  .register ul li{
    margin-top: 30px;
  }
  .yzm_img{
    float: right;
  }
  .tspop{
    font-size: 14px;
    line-height: 40px;
    color:#787A80 ;
  }
  .zhanghu a{
    text-decoration: underline;
    color:#25449A;
    font-size: 14px;
  }
</style>
<style>
 .zhuceinput .ivu-input-large{
    height: 48px;
    background:#E6EBF5;
    padding-left: 15px;
  }
  .ivu-input-large::-webkit-input-placeholder{
    color:#787A80;
  }
  .ivu-input-large::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color:#787A80;
  }
  .ivu-input-large:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    color:#787A80;
  }
  .ivu-input-large:-ms-input-placeholder{  /* Internet Explorer 10-11 */
    color:#787A80;
  }
  .ivu-btn-primary{
    height: 48px;
    font-size: 12px;
    color: #fff;
    background: #25449A;
  }
</style>
